
@font-face {
  font-family: 'galaxy';
  src: url('../../fonts/Edge-of-the-Galaxy-Poster.woff');
  src: url('../../fonts/Edge-of-the-Galaxy-Poster.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Edge-of-the-Galaxy-Poster.svg#Edge-of-the-Galaxy-Poster') format('svg'),
       url('../../fonts/Edge-of-the-Galaxy-Poster.ttf') format('truetype'),
       url('../../fonts/Edge-of-the-Galaxy-Poster.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

.comingSoon {
  /* height: 100vh; */
  /* overflow: hidden !important; */

  /* delettop*/
  margin-top: 2em;
  /* height: calc(var(--vh, 1vh) * 100); */

  display: flex;
  flex-direction: column;
  align-items: center;

}
.comingSoon::-webkit-scrollbar {
  width: 0.2em;
  background-color: #070F1E;
}

.comingSoon::-webkit-scrollbar-thumb {
background-color: rgb(174, 202, 150);
border-radius: 1em;
height: 1em !important;
}

.comingSoon > div{
  display: flex;
  justify-content: center;
  margin: 0px !important;
}

.comingSoon > div > div {
  margin: 0em !important
}


.mainMsg{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0em 0em !important;

}


.msg1{
  height: 6.5em !important;
}
.msg2{
  margin: 0em 2em !important;
  display: inherit;
  flex-direction: column;
  align-items: center;
  /* height: 6em !important; */
  margin-top: 0em !important;
  width: auto;


}

@keyframes swing {
  0% { transform: rotate(3deg); }
  100% { transform: rotate(-3deg); }
}

.imgTop {
  animation: swing ease-in-out 1.5s infinite alternate;
  transform-origin: right -25px;
  float:left;
  /* box-shadow: 5px 5px 10px rgba(0,0,0,0.5); */
}

.imgTop{
  display: inherit;
  align-items: center;


}

.logo {
  margin: 2em 0em 0em 0em !important;
}
.imgBottom {
  display: flex;
  justify-content: space-between!important;
  align-items: flex-end !important;
  /* position:-webkit-sticky !important; */


  background-color: transparent;
  width: 100%;


}


.img1{
  width: 15em ;
  height: 30em ;
  margin: 5em 4em 0em 0em;

}

.img2{
  width: 30%;
  height: 10%;
  margin-left: -5em;
  margin-bottom: -2em;
  margin-top: 5em;
  cursor: pointer
}

.img2:hover{
  transform: rotate(355deg);
}

.object {
  animation: MoveUpDown 1s linear infinite;
  position: absolute;
  left: 0;
  bottom: 0;
}

/* @keyframes MoveUpDown {
  0%, 100% {
    bottom: -200px;
  }
  50% {
    bottom: -100px;
  }
} */

 /* .bottomHalf {
    animation: 1s slide-up;
    margin-top: 0%;
  } */

  @keyframes slideup {
    0%{bottom:-50px}
    100%{bottom:0;}
  }

  .bottomHalf{
    display:flex;
      justify-self: flex-end !important;
     /* height: 100%; */
     /* position:sticky !important;
     bottom:0 !important; */
     position:relative;
     overflow:hidden;
    height: 12em;



    }


    .imgBottom{
      position:absolute;
      bottom:-100px;;
      height:50px;
      width:100%;
      /* left:0; */
      animation-name:slideup;
      animation-delay:0.5s;
      animation-duration:0.8s;
      animation-fill-mode:forwards;
      animation-timing-function:ease-out;
    }

  .img3{
    width: 13em;
    height: 12em;
    /* animation: MoveUpDown 5s linear infinite;
    position: absolute;
    left: 0;
    bottom: 0; */
    transform: rotate(180deg) !important

   }

.img4{
  width: 12em;
  height: 7em;
  /* animation: MoveUpDown 5s linear infinite;
    position: absolute;
    right: 0;
    bottom: 0; */
    transform: rotate(-90deg) !important
    /* position:absolute;
    bottom:-50px;;
    height:50px;
    width:100%;
    right:0;
    animation-name:slideup;
    animation-delay:0.5s;
    animation-duration:0.8s;
    animation-fill-mode:forwards;
    animation-timing-function:ease-out; */
}



@media screen and (max-width: 1386px){
  .comingSoon h1 {
    font-size: 750% !important;
  }
  .comingSoon h2 {
    font-size: 450% !important;
  }
  .img1{
    width: 12em ;
    height: 24em ;
  }
  .msg2{
    margin-top: -2em !important;
  }
}

@media screen and (max-width: 1300px) {
  .comingSoon h1 {
    font-size: 600% !important;
  }
  .comingSoon h2 {
    font-size: 350% !important;
  }
  .msg1{
    height: 4.5em !important;
  }
  .img1{
    width: 10em ;
    height: 20em ;
  }
  .msg2{
    margin-top: -1em !important;
  }
}

@media screen and (max-width: 1200px){
  .comingSoon h1 {
    font-size: 600% !important;
  }
  .comingSoon h2 {
    font-size: 350% !important;
  }
  .logo{
    margin-left: 7em !important
  }
  .mainMsg{
    margin-left: 0em !important;
    width: 85%
  }
}

@media screen and (max-width: 1100px) {
  .comingSoon h1 {
    font-size: 450% !important;
  }
  .comingSoon h2 {
    font-size: 300% !important;
  }
  .msg1{
    height: 4.5em !important;
  }

  .img1{
    width: 9em ;
    height: 18em ;
  }
}


@media screen and (max-width: 800px) {
  .comingSoon{
    margin-top: 2em
    }
  .comingSoon h1 {
    font-size: 450% !important;
  }
  .comingSoon h2 {
    font-size: 300% !important;
  }
  .msg1{
    height: 3.7em !important;
  }

  .comingSoon > div {
    display: flex;
    flex-direction: column;
    align-items: center
  }
  .mainMsg {
    width: 100%;
    align-items: center !important;
  }
  .logo {
    display: flex;
    justify-content: center;
    margin-left: -4em !important

  }
  .imgBottom{
    flex-direction: row !important;
    align-items: flex-end !important;

  }

  .img1{
    margin-top: 1em;
  }
  .img2{
    margin-left: 0em;
    margin-top: -1em;
  }
    .msg2 h2{
    margin-top: 0em;
  }
  .msg2 {
    height: 14em !important;
  }
  .img3{
    width: 9em;
    height: 7em;
   }

.img4{
  width: 8em;
  height: 5em;

}
.mainMsg{
  margin: 0em !important;
}



}


@media screen and (max-width: 660px) {
  .comingSoon {
    height: 100% !important;

  }


  .img1{
    margin-top: 2em !important;
  }

  .comingSoon h1 {
    font-size: 400% !important;
  }
  .comingSoon h2 {
    font-size: 280% !important;
  }
  .msg1{
    height: 1em !important;
  }
  .msg2{
    margin: 0em !important
  }
  .mainMsg{
    width: 100%;
    margin: 0em 0em !important;
    display: flex !important;
    align-items: center !important;
  }
  .msg2 h2{
    margin-top: 0em;
  }


}

@media screen and (max-width: 500px) {
  .comingSoon {
    /* display: flex; */
    height: 100% !important;

  }
  .comingSoon h1 {
    font-size: 390% !important;
    letter-spacing: 4px;
    text-shadow: 5px 4px 0px #1936c7,
    10px 8px 0px #011476

  }
  .comingSoon h2 {
    font-size: 300% !important;
    letter-spacing: 2px;
  }


  .mainMsg{
    width: 100%;

  }
  .img2{
    width: 30%;
    height: 10%;
  }
  /* .msg2 h2{
    margin-top: -0.5em;
  }*/
}

.links img{
  width: 1em !important;
  height: 1em !important;
  margin: 0em 0.2em 0em 0em;
}

.links a{
  text-decoration: none;
  color: white;
}
.links {
  /* margin: 0em 1em 0em 0em !important; */
  display: flex;
  justify-content: flex-start;
  margin: -2em !important;
  /* height: 1em !important */
}

.links div{
  /* height: 1em !important; */
  display: flex !important;
  margin-top: 0em !important
}

.comingSoon button{
  cursor: pointer;
  background-color: #070F1E;
  border: none;
  color: white;
  display: flex;
  align-items: flex-start;
  font-family: 'galaxy';
  letter-spacing: 1.5px;
  font-size: 1.5em;
  margin: 0em .2em 0em 0em;
  padding: 0.5em;
}

/* .comingSoon button:hover{
  border: 1px solid white;
  border-radius: 1em;

} */

.comingSoon h1, h2{
  margin: 0px;
  color: rgb(174, 202, 150);
  font-family: 'galaxy';
  font-weight: 100;
  letter-spacing: 8px;
}

.comingSoon h1{
  font-size: 950%;
  /* text-shadow: 6px 5px 0px #1936c7, */
  text-shadow: 5px 4px 0px #1936c7,
  10px 8px 0px #011476
  /* 15px 15px 0px #0d29fa; */;

 }

.comingSoon h2{
 font-size: 500%;
 letter-spacing: 4px;
 text-shadow: 5px 2px 0px #1936c7,
 10px 4px 0px #011476
  /* 15px 15px 0px #0d29fa; */;
  /* margin-right: .7em; */
}

.comingSoon div{
  margin: 2em 0em;
}

